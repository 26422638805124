<template>

	<div class="buycard">
		<div class="title">
			<p>体验卡</p>
			
			<img src="../../assets/other/right.png" alt="">
			<p class="active">体验卡购买</p>
		</div>
        <div class="user">
            <h4>体验卡购买</h4>
            <div class="card">
                <img src="../../assets/user/card1.png" alt="" v-show="sign==0">
                <img src="../../assets/user/card2.png" alt="" v-show="sign==1">
                <img src="../../assets/user/card3.png" alt="" v-show="sign==2">
                <div>
                    <div class="chose">
                        <div :class="{'active':sign==i}" @click="sign=i" v-for="(item,i) in list" :key="i">
                            <p>{{item.name}}</p>
                            <p>¥{{item.price  | priceFilter}}</p>
                            <p>有效期{{item.time}}天</p>
                        </div>
                        
                    </div>
                    <div class="buy">立即购买</div>
                </div>
            </div>
        </div>
	</div>

</template>

<script>
import { buyCardviewAPI} from "@/api/user";
export default {

  data () {
    return {
      sign: 0,
      list:[],
      obj:{}

    }
  },
  created () {
      this.getList()
  },
  methods: {
    tourl () {
      this.$router.push({ path: '/user/orderInfo' })
    },
    tourl2 () {
      this.$router.push({ path: '/user/buycard' })
    },
    getList(){
        buyCardviewAPI().then(res=>{
            this.list=res.cateList
        })
		 
	}
  }
}
</script>

<style lang="scss" scoped="scoped">
.title{
	display: flex;
	align-items: center;
	margin: 5px 0;

	p{
		font-size: 15px;
		color: #666666;
		line-height: 21px;
	}
	.active{
		color: #FF7C00;
	}

	img{
		width: 10px;
		height: 11px;
		margin: 0 10px;
	}
}
.user{
    padding: 5px 20px 20px;
    background: #ffffff;
    h4{
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        font-weight: 500;
        line-height: 45px;
        text-indent: 10px;
    }
    .card{
        display: flex;
        align-items: center;
        .chose{
            display: flex;
            align-items: center;
          
            div{
                cursor: pointer;
                width: 140px;
                height: 88px;
                opacity: 1;
                background: #f9f9f9;
                border-radius: 6px;
                margin: 0 10px;
                padding: 10px;
                 border: 1px solid #fff;
                p:nth-child(1){
                    color: #000000;
                    font-size: 16px;
                }
                 p:nth-child(2){
                    color: #ff4c12;
                    font-size: 16px;
                    line-height: 30px;
                }
                 p:nth-child(3){
                    color: #666;
                    font-size: 12px;
                }
            }
            .active{
                background: #fff6f3;
                border: 1px solid #ff4c12;
                border-radius: 7px;
            }
        }
        .buy{
            width: 142px;
            height: 45px;
            opacity: 1;
            background: #ff7c00;
            border-radius: 6px;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            text-align: center;
            margin: 10px 0 0 10px;
        }
    }
}
</style>
